import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Início | Ink Haven Tattoo Studio
			</title>
			<meta name={"description"} content={"Entre num mundo onde a criatividade e o trabalho artesanal convergem. No Ink Haven, não estamos apenas a tatuar - estamos a criar obras-primas pessoais."} />
			<meta property={"og:title"} content={"Início | Ink Haven Tattoo Studio"} />
			<meta property={"og:description"} content={"Entre num mundo onde a criatividade e o trabalho artesanal convergem. No Ink Haven, não estamos apenas a tatuar - estamos a criar obras-primas pessoais."} />
			<meta property={"og:image"} content={"https://airtotopstar.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://airtotopstar.com/img/5692224.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://airtotopstar.com/img/5692224.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://airtotopstar.com/img/5692224.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://airtotopstar.com/img/5692224.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://airtotopstar.com/img/5692224.png"} />
			<meta name={"msapplication-TileImage"} content={"https://airtotopstar.com/img/5692224.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://airtotopstar.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				color="--light"
				font="normal 400 18px/1.5 --fontFamily-sans"
				letter-spacing="1px"
			>
				Bem-vindo ao Ink Haven Tattoo Studio
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="normal 700 64px/1.2 --fontFamily-sans"
				letter-spacing="1px"
				color="#ff436d"
				padding="0px 250px 0px 250px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
			>
				Ink Haven
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 260px 0px 260px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Entre num mundo onde a criatividade e o trabalho artesanal convergem. No Ink Haven, não estamos apenas a tatuar - estamos a criar obras-primas pessoais. Quer se trate da sua primeira tatuagem ou de uma adição à sua coleção, o nosso estúdio dedica-se a proporcionar uma experiência única e confortável. Junte-se a nós e vamos dar vida à sua visão na sua pele.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0">
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Sobre o Ink Haven
				</Text>
				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="center"
					color="--darkL2"
					lg-width="100%"
				>
					O Estúdio de Tatuagens Ink Haven é mais do que apenas um local onde se pode fazer tatuagens. É um santuário criativo onde a arte e a expressão individual ganham vida na pele. O nosso estúdio combina um ambiente descontraído com os mais elevados padrões de profissionalismo e higiene, garantindo uma experiência de tatuagem memorável e segura.
				</Text>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Text
				margin="0px 0px 50px 0px"
				font="--headline2"
				color="--dark"
				sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
			>
				Porquê escolher o Ink Haven?
			</Text>
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://airtotopstar.com/img/2.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					- Artistas experientes: A nossa talentosa equipa de artistas oferece uma gama diversificada de estilos, do tradicional ao contemporâneo, garantindo que a sua visão é transformada em arte.
					<br />
					<br />
					- Desenhos personalizados: Especializamo-nos na criação de desenhos de tatuagens à medida que se relacionam com a sua história e estilo pessoal.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
						- Higiene de última geração: A sua segurança é a nossa prioridade. Utilizamos as mais recentes técnicas de esterilização e tintas de alta qualidade.
						<br />
						<br />
						- Abordagem consultiva: Acreditamos num processo colaborativo, onde as suas ideias são ouvidas e aperfeiçoadas com orientação especializada.
					</Text>
					<Button href="/contacts" type="link" text-decoration-line="initial">
						Entrar em contato
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://airtotopstar.com/img/3.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});